<script>
import { useStore } from 'vuex'
import { onMounted, reactive, toRefs } from 'vue'

const stepName = 'YearlyIncome'

const answers = [
  {
    value: 'less_than_20000',
    key: 'Less than 20000'
  },
  {
    value: '20k_to_40k',
    key: '40,000 - 60,000'
  },
  {
    value: 'more_than_60000',
    key: '60,000 or more'
  }
]

export default {
  name: stepName,

  emits: ['next-step'],

  setup(_, {emit}) {
    const store = useStore()

    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })

    const state = reactive({
      yearly_income: {
        value: 'less_than_20000',
        key: 'Less than 20000'
      }
    })

    const setAnswer = () => {
      store.commit('SET_FIELD', {field: 'yearly_income', value: state.yearly_income.value})
      emit('next-step', stepName)
    }

    return {
      answers,
      setAnswer,
      ...toRefs(state)
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="box">
        <select
          class="select-dropdown height-select-dropdown"
          v-model="yearly_income.value"
        >
          <option
            v-for="answer in answers"
            :value="answer.value"
          >
            {{answer.key}}
          </option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="box">
        <button
          class="main-button main-button-w mt-md"
          @click="setAnswer"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../assets/styles/component_styles/questionnaire/height.scss';

.select-dropdown.height-select-dropdown {
  height: 50px;
}
</style>